import React from "react";
import Hero from "../components/hero/Hero";
// import Navigation from "../navigation/Navigation";
// import AgentCluster from "../components/AgentCluster/AgentCluster";
import ClusterAgentsSlider from "../components/AgentCluster/ClusterAgentsSlider";
import { salesAgents, marketingAgents } from "../utils/AgentsData";

function Home() {
  return (
    <>
      <Hero />
      <ClusterAgentsSlider title="Sales" agentCluster={salesAgents} />
      <ClusterAgentsSlider title="Marketing" agentCluster={marketingAgents} />
      {/* <ClusterAgentsSlider title="Support" /> */}
    </>
  );
}

export default Home;
