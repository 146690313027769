import React from "react";

const Journey = () => {
  const steps = [
    {
      title: "The Challenge",
      description:
        "Solo founders often excel at technical development but struggle with sales and marketing.",
      icon: (
        <svg
          className="w-10 h-10 text-purple-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
          ></path>
        </svg>
      ),
    },
    {
      title: "The Solution",
      description:
        "AI agents bridge the gap, handling sales and marketing while you focus on innovation.",
      icon: (
        <svg
          className="w-10 h-10 text-cyan-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 10V3L4 14h7v7l9-11h-7z"
          ></path>
        </svg>
      ),
    },
    {
      title: "The Impact",
      description:
        "Unlock smarter decisions, enhanced operations, and sustainable growth.",
      icon: (
        <svg
          className="w-10 h-10 text-green-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
          ></path>
        </svg>
      ),
    },
  ];

  return (
    <div className="relative bg-gradient-to-br from-gray-900 via-slate-800 to-zinc-900 text-white min-h-screen flex items-center justify-center overflow-hidden px-4">
      <div className="max-w-4xl mx-auto text-center space-y-8">
        {/* Title */}
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
          Your AI-Powered Journey
        </h2>

        {/* Steps */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <div
              key={index}
              className="relative bg-gray-800 rounded-lg shadow-lg p-6 md:p-8 text-center md:text-left hover:shadow-xl transition-shadow duration-300"
            >
              {/* Icon */}
              <div className="absolute -top-6 left-1/2 md:left-8 transform -translate-x-1/2 md:translate-x-0 bg-gray-700 p-3 md:p-4 rounded-full shadow-md">
                {step.icon}
              </div>

              {/* Content */}
              <div className="mt-10 md:mt-12">
                <h3 className="text-lg md:text-xl font-semibold mb-3 md:mb-4">
                  {step.title}
                </h3>
                <p className="text-gray-400 text-sm md:text-base">
                  {step.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Journey;
