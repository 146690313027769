import React, { useState, useRef, useEffect } from "react";

const ClusterAgentsSlider = ({ title = "Marketing", agentCluster = [] }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const sliderRef = useRef(null);

  // Mouse and Touch Event Handlers
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  // Touch Events
  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    sliderRef.current.scrollLeft = scrollLeft - walk;
  };

  useEffect(() => {
    const handleResize = () => {
      if (sliderRef.current) {
        const slideWidth = sliderRef.current.offsetWidth / 3;
        sliderRef.current.scrollLeft = currentSlide * slideWidth;
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [currentSlide]);

  return (
    <div className="w-full bg-black   pb-8 md:pb-0">
      <div className="max-w-7xl mx-auto px-4 py-12 md:py-20">
        <div className="mb-12">
          <h1 className="text-4xl md:text-5xl font-bold tracking-tight text-white">
            {title}
          </h1>
        </div>

        <div className="relative">
          <div className="flex items-center">
            <div
              ref={sliderRef}
              className="w-full overflow-x-auto hide-scrollbar snap-x snap-mandatory"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseLeave}
              onMouseMove={handleMouseMove}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleMouseUp}
              onTouchMove={handleTouchMove}
              style={{
                scrollBehavior: "smooth",
                cursor: isDragging ? "grabbing" : "grab",
                WebkitOverflowScrolling: "touch",
              }}
            >
              <div className="flex gap-4 md:gap-6 pb-4 md:pb-0">
                {agentCluster.map((slide, index) => (
                  <div
                    key={index}
                    className="w-[85vw] sm:w-[45vw] md:w-[32vw] max-w-lg flex-shrink-0 snap-center"
                  >
                    <div
                      className={`h-[60vh] sm:h-[65vh] md:h-[70vh] rounded-2xl overflow-hidden bg-gradient-to-br ${slide.gradient} p-6 md:p-8 relative group hover:shadow-lg transition-all border border-white/20`}
                    >
                      <div className="relative z-10 h-full flex flex-col justify-between text-white">
                        <div>
                          <div className="flex items-center gap-3 mb-4">
                            {slide.icon}
                            <span
                              className={`text-${slide.accent}-300 text-sm font-medium`}
                            >
                              AI Agent
                            </span>
                          </div>
                          <h2 className="text-lg sm:text-xl md:text-2xl font-bold mb-2">
                            {slide.title}
                          </h2>
                          <p className="text-sm md:text-base text-white/70 line-clamp-3">
                            {slide.description}
                          </p>
                        </div>

                        {/* <button className="mt-4 px-4 py-2 rounded-xl bg-white/10 hover:bg-white/20 transition-colors text-sm md:text-base backdrop-blur-sm">
                          Learn more
                        </button> */}
                        <div className="flex items-center gap-3 mt-4">
                          <div className="w-2 h-2 bg-yellow-500 rounded-full animate-pulse" />
                          <span className="text-xs text-white/60">
                            Agent prototype being refined
                          </span>
                        </div>
                      </div>

                      <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />
                      <div
                        className={`absolute -right-24 -bottom-24 w-64 h-64 md:w-96 md:h-96 bg-${slide.accent}-500/20 blur-3xl rounded-full`}
                      />
                      <div
                        className={`absolute -left-24 -top-24 w-64 h-64 md:w-96 md:h-96 bg-${slide.accent}-500/10 blur-3xl rounded-full`}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx global>{`
        .hide-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </div>
  );
};

export default ClusterAgentsSlider;
