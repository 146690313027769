import {
  Sparkles,
  Bot,
  Mic,
  Target,
  Mail,
  Pencil,
  Search,
  Smartphone,
  BarChart,
} from "lucide-react";

export const salesAgents = [
  {
    title: "Sales Representative AI",
    description:
      "Conducts product demonstrations and handles customer inquiries autonomously.",
    icon: <Bot className="w-6 h-6 text-blue-400" />,
    gradient: "from-blue-500/20 to-cyan-500/20",
    accent: "blue",
    originalIcon: "🤖",
  },
  {
    title: "Sales Call Analyzer",
    description:
      "Transcribes and analyzes sales calls in real-time, providing insights for improvement.",
    icon: <Mic className="w-6 h-6 text-green-400" />,
    gradient: "from-green-500/20 to-teal-500/20",
    accent: "green",
    originalIcon: "🎙️",
  },
  {
    title: "Lead Generation Assistant",
    description:
      "Identifies and qualifies potential leads by analyzing online behavior and industry trends.",
    icon: <Target className="w-6 h-6 text-red-400" />,
    gradient: "from-red-500/20 to-orange-500/20",
    accent: "red",
    originalIcon: "🎯",
  },
  {
    title: "Outreach Optimizer",
    description:
      "Crafts personalized email campaigns and optimizes content for maximum engagement.",
    icon: <Mail className="w-6 h-6 text-indigo-400" />,
    gradient: "from-indigo-500/20 to-purple-500/20",
    accent: "indigo",
    originalIcon: "📧",
  },
];

export const marketingAgents = [
  {
    title: "Content Creation Assistant",
    description:
      "Generates blog posts, social media content, and ad copy tailored to your target audience.",
    icon: <Pencil className="w-6 h-6 text-purple-400" />,
    gradient: "from-purple-500/20 to-blue-500/20",
    accent: "purple",
    originalIcon: "✍️",
  },
  {
    title: "SEO Strategist",
    description:
      "Analyzes keywords and optimizes website content to improve search engine rankings.",
    icon: <Search className="w-6 h-6 text-cyan-400" />,
    gradient: "from-cyan-500/20 to-sky-500/20",
    accent: "cyan",
    originalIcon: "🔍",
  },
  {
    title: "Social Media Manager",
    description:
      "Schedules posts, engages with followers, and analyzes performance across platforms.",
    icon: <Smartphone className="w-6 h-6 text-emerald-400" />,
    gradient: "from-emerald-500/20 to-green-500/20",
    accent: "emerald",
    originalIcon: "📱",
  },
  {
    title: "Marketing Campaign Optimizer",
    description:
      "Analyzes campaign performance and provides data-driven recommendations for improvement.",
    icon: <BarChart className="w-6 h-6 text-rose-400" />,
    gradient: "from-rose-500/20 to-pink-500/20",
    accent: "rose",
    originalIcon: "📊",
  },
];
