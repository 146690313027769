import React from "react";
import { X, MessageCircle, Rocket, Mail } from "lucide-react";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-b from-black to-gray-900 text-gray-300">
      <div className="max-w-7xl mx-auto px-4 py-12">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          {/* Brand Section */}
          <div className="space-y-4 md:col-span-2">
            <div className="flex items-center space-x-2">
              <Rocket className="text-purple-400" size={24} />
              <h2 className="text-2xl font-bold text-white">AAAGentive</h2>
            </div>
            <p className="text-sm text-gray-400">
              Empowering solo founders and small businesses with AI-driven
              solutions for sales, marketing, and beyond. Currently in
              development phase.
            </p>
            <div className="flex items-center space-x-1">
              <span className="h-2 w-2 bg-green-400 rounded-full animate-pulse"></span>
              <span className="text-xs text-green-400">Development Active</span>
            </div>
          </div>

          {/* Quick Links */}
          <div className="space-y-4">
            <h3 className="text-white font-medium">Early Access</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <a href="#" className="hover:text-purple-400 transition">
                  Join Waitlist
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-purple-400 transition">
                  Documentation
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-purple-400 transition">
                  Roadmap
                </a>
              </li>
              <li>
                <span className="px-2 py-1 text-xs bg-purple-500/20 text-purple-300 rounded-full">
                  Beta Coming Soon
                </span>
              </li>
            </ul>
          </div>

          {/* Community */}
          <div className="space-y-4">
            <h3 className="text-white font-medium">Community</h3>
            <ul className="space-y-2 text-sm">
              {/* <li className="flex items-center space-x-2">
                <Github size={16} />
                <a href="#" className="hover:text-purple-400 transition">
                  GitHub
                </a>
              </li> */}
              {/* <li className="flex items-center space-x-2">
                <Discord size={16} />
                <a href="#" className="hover:text-purple-400 transition">
                  Discord
                </a>
              </li> */}
              <li className="flex items-center space-x-2">
                <X size={16} />
                <a href="#" className="hover:text-purple-400 transition">
                  Twitter
                </a>
              </li>
              <li className="flex items-center space-x-2">
                <Mail size={16} />
                <a href="#" className="hover:text-purple-400 transition">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="pt-8 border-t border-gray-800 flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <div className="flex items-center space-x-4 text-sm">
            <span>© 2024 AAAGentive</span>
            <a href="#" className="hover:text-purple-400 transition">
              Privacy
            </a>
            <a href="#" className="hover:text-purple-400 transition">
              Terms
            </a>
          </div>

          <div className="flex items-center space-x-2 bg-purple-500/10 px-3 py-1 rounded-full">
            <MessageCircle size={14} className="text-purple-400" />
            <span className="text-xs">We're actively collecting feedback</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
