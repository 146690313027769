import React from "react";
import { ArrowRight, Zap } from "lucide-react";

const AIHero = () => {
  return (
    <div className="relative bg-gradient-to-br from-gray-900 via-slate-800 to-zinc-900 text-white min-h-screen flex items-center justify-center overflow-hidden px-4">
      <div className="max-w-4xl mx-auto text-center space-y-8">
        {/* Badge */}
        <div className="inline-flex items-center bg-gray-800/50 border border-gray-700 rounded-full px-3 py-1 text-xs">
          <Zap className="mr-2 text-purple-400" size={14} />
          AI Agents for Business Transformation
        </div>

        {/* Title */}
        <h1 className="text-4xl md:text-6xl font-bold tracking-tight">
          Sales & Marketing AI Agents <br />
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-cyan-400">
            Bridging Tech Expertise with Business Success
          </span>
        </h1>

        {/* Description */}
        <p className="text-gray-300 text-lg md:text-xl max-w-2xl mx-auto">
          We Are Building AI Agents To Scale Your Vision Without Scaling Your
          Team.
        </p>

        {/* Development Notice */}
        <div className="bg-yellow-500/20 text-yellow-300 border border-yellow-500 rounded-lg p-3 text-sm">
          🚧 Our platform is under development. Join the waitlist to be among
          the first to experience next-gen AI for your business.
        </div>

        {/* Buttons */}
        <div className="flex justify-center space-x-2">
          <button className="flex items-center bg-purple-600 text-white px-4 py-2 rounded-lg text-sm hover:bg-purple-700 transition">
            Get Started <ArrowRight className="ml-1" size={16} />
          </button>
          <button className="flex items-center border border-gray-700 text-gray-300 px-4 py-2 rounded-lg text-sm hover:bg-gray-800 transition">
            Join Waitlist
          </button>
        </div>

        {/* Features */}
        {/* <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-8">
          {[
            {
              value: "Sales AI",
              color: "text-purple-400",
              description: "Boost sales with AI-driven strategies.",
            },
            {
              value: "Marketing AI",
              color: "text-cyan-400",
              description: "Reach your audience with precision.",
            },
            {
              value: "Research AI",
              color: "text-green-400",
              description: "Uncover insights to drive innovation.",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="bg-gray-800/50 p-4 rounded-lg border border-gray-700"
            >
              <div className={`text-lg font-bold ${item.color}`}>
                {item.value}
              </div>
              <p className="text-sm text-gray-400 mt-2">{item.description}</p>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default AIHero;
