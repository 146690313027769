// Navigation.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, X, ChevronDown } from "lucide-react";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const solutions = [
    { name: "Marketing", href: "#" },
    { name: "Sales", href: "#" },
    { name: "Automation", href: "#" },
    { name: "Analytics", href: "#" },
    { name: "Integration", href: "#" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.pageYOffset > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    // <nav className="w-full flex justify-center px-4 py-2 ">
    <nav
      className={`w-full flex justify-center px-4 py-2 z-20 ${
        isSticky ? "sticky top-0 z-10" : ""
      }`}
    >
      <div className="w-full md:w-2/3 bg-white rounded-lg shadow-md">
        <div className="max-w-screen-xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <div className="flex-shrink-0">
              <span className="text-xl text-black font-bold">AAAGentive</span>
            </div>

            {/* Mobile menu button */}
            <div className="flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-gray-800 hover:text-gray-600"
              >
                {isOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>

            {/* Desktop menu */}
            <div className="hidden md:flex md:items-center md:space-x-8">
              {/* Solutions Dropdown */}
              <div className="relative">
                <button
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center text-gray-800 hover:text-gray-600"
                >
                  Solutions
                  <ChevronDown size={16} className="ml-1" />
                </button>
                {isDropdownOpen && (
                  <div className="absolute z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1">
                      {solutions.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <Link
                to="/#"
                className="block px-3 py-2 text-base text-gray-700 hover:bg-gray-100 rounded-md"
              >
                About
              </Link>
              <Link
                to="/#"
                className="block px-3 py-2 text-base text-gray-700 hover:bg-gray-100 rounded-md"
              >
                Documentation
              </Link>

              {/* <a href="#" className="text-gray-800 hover:text-gray-600">
                About
              </a>
              <a href="#" className="text-gray-800 hover:text-gray-600">
                Documentation
              </a> */}
              <div className="flex items-center space-x-4">
                <button className="px-4 py-2 text-gray-800 hover:text-gray-600">
                  Login
                </button>
                <button className="px-4 py-2 bg-black text-white rounded-md hover:bg-gray-700">
                  Sign up
                </button>
              </div>
            </div>
          </div>

          {/* Mobile menu */}
          {isOpen && (
            <div className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {solutions.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="block px-3 py-2 text-base text-gray-700 hover:bg-gray-100 rounded-md"
                  >
                    {item.name}
                  </a>
                ))}
                <Link
                  to="/about"
                  className="block px-3 py-2 text-base text-gray-700 hover:bg-gray-100 rounded-md"
                >
                  About
                </Link>
                <Link
                  to="/documentation"
                  className="block px-3 py-2 text-base text-gray-700 hover:bg-gray-100 rounded-md"
                >
                  Documentation
                </Link>
                <div className="mt-4 space-y-2">
                  <button className="w-full px-4 py-2 text-center text-gray-800 hover:text-gray-600">
                    Login
                  </button>
                  <button className="w-full px-4 py-2 text-center bg-blue-600 text-white rounded-md hover:bg-blue-700">
                    Sign up
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
