// import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
// import { Pagination, Navigation, Autoplay } from "swiper/modules";

// // Import the slide components
// import InteractiveAgent from "../slides/InteractiveAgent";
// import InformativeSlide from "../slides/InformativeSlide";
// import ProductDemoAgent from "../slides/ProductDemoAgent"; // Ensure this is defined
// import AIHero from "../slides/AIHero"; // Ensure this is defined

// const Hero = () => {
//   return (
//     <>
//       {/* <div className="relative w-full h-screen "> */}
//       <div className="relative w-full bg-black flex h-screen items-center justify-center">
//         <Swiper
//           modules={[Pagination, Navigation, Autoplay]}
//           spaceBetween={50}
//           slidesPerView={1}
//           pagination={{
//             clickable: true,
//             renderBullet: (index, className) => {
//               return `<span class="${className}">•</span>`;
//             },
//           }}
//           //   autoplay={{ delay: 5000 }}
//           loop
//           // navigation
//           className="w-[97%] h-[95%] rounded-xl"
//         >
//           <SwiperSlide>
//             <AIHero />
//           </SwiperSlide>
//           <SwiperSlide>
//             <ProductDemoAgent />
//           </SwiperSlide>

//           <SwiperSlide>
//             <InteractiveAgent /> {/* Interactive slide */}
//           </SwiperSlide>
//           <SwiperSlide>
//             <InformativeSlide /> {/* Informative slide with CTA */}
//           </SwiperSlide>
//         </Swiper>
//       </div>
//     </>
//   );
// };

// export default Hero;

import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { ChevronLeft, ChevronRight } from "lucide-react";

// Import the slide components
import Journey from "../slides/Journey";
import InformativeSlide from "../slides/InformativeSlide";
import ProductDemoAgent from "../slides/ProductDemoAgent";
import AIHero from "../slides/AIHero";

const Hero = () => {
  const swiperRef = useRef(null);
  const [isInteracting, setIsInteracting] = useState(false);
  const startX = useRef(0);

  const handleNext = () => {
    swiperRef.current?.swiper.slideNext();
  };

  const handlePrev = () => {
    swiperRef.current?.swiper.slidePrev();
  };

  const handleMouseDown = (e) => {
    setIsInteracting(true);
    startX.current = e.clientX;
  };

  const handleMouseMove = (e) => {
    if (!isInteracting) return;

    const currentX = e.clientX;
    const diffX = startX.current - currentX;

    if (Math.abs(diffX) > 50) {
      if (diffX > 0) {
        handleNext();
      } else {
        handlePrev();
      }
      setIsInteracting(false);
    }
  };

  const handleMouseUp = () => {
    setIsInteracting(false);
  };

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const currentX = e.touches[0].clientX;
    const diffX = startX.current - currentX;

    if (Math.abs(diffX) > 50) {
      if (diffX > 0) {
        handleNext();
      } else {
        handlePrev();
      }
    }
  };

  return (
    <div
      className="relative w-full bg-black flex h-screen items-center justify-center"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <Swiper
        ref={swiperRef}
        modules={[Pagination, Navigation, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className}">•</span>`;
          },
        }}
        autoplay={{ delay: 7000 }}
        loop
        className="w-[95%] h-[100%] rounded-xl"
      >
        <SwiperSlide>
          <AIHero />
        </SwiperSlide>
        <SwiperSlide>
          <Journey />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Hero;
